import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Home from './pages/Home';
import InscricaoImprensa from './pages/InscricaoImprensa';
import InscricaoUpdate from './pages/InscricaoUpdate';
import Inscricao from './pages/Inscricao';
import Login from './pages/Login';
import Salas from './pages/Salas';
import Agenda from './pages/Agenda';
import AgendaUser from './pages/AgendaUser';
import Watch from './pages/Watch';
import News from './pages/News';

export default function Routing() {
    
    /*function GetRedirect(){
        const today = dayjs();
        const formattedDate = today.format('YYYY-MM-DD HH:mm');
        const targetDate = dayjs('2024-02-22 10:00');
        const hasPassed = targetDate.isBefore(formattedDate);
        if(hasPassed) return <Inscricao1 />; else return <Inscricao2 />;
    }*/

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/home" element={<Home />} />
                {/* <Route path="/inscricao-update" element={<InscricaoUpdate />} /> */}
                <Route path="/inscricao" element={<Inscricao />} />
                <Route path="/imprensa" element={<InscricaoImprensa />} />
                <Route path="/login" element={<Login />} />
                {/* <Route path="/assistir" element={<Login />} /> */}
                {/* <Route path="/salas" element={<Salas />} />
                <Route path="/sala/:url?" element={<Salas />} /> */}
                <Route path="/painelistas" element={<News />} />
                <Route path="/painelista/:url?" element={<News />} />
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/assistir" element={<Watch />} />
                <Route path="/minha-agenda" element={<AgendaUser />} />
                <Route path="/contato" element={<Navigate replace to="/" />} />
            </Routes>
        </BrowserRouter>
    );
}