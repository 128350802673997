import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuCalendarDays } from "react-icons/lu";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';

import thumb from '../../assets/images/thumb.jpg';
import thumb_en from '../../assets/images/thumb_en.jpg';

export default function Watch() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const [events, setEvents] = useState([]);


    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40 text-center form">
                    <h3 className="title_b mb-60">
                        {language.menu_watch}
                    </h3>
                    <div className="mt-10 mb-40">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-big">{language.soon}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
