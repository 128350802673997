import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');


    return (
        <div className="page">
            <Header />
            <div className="mt-40 mb-30">
                <div className="container">
                    <div className="col-md-10 m-auto">
                        {/* {lang==='pt' ?
                        <div className="alert-text">
                            <p>asasasasa</p>
                        </div>:
                        <div className="alert-text">
                            <p>asasasasa</p>
                        </div>} */}
                        {lang==='pt' ?
                        <div className="text">
                            <p>Duas importantes forças-tarefa, criadas pela Presidência brasileira no G20 deste ano, estão em andamento: a Mobilização contra as Mudanças Climáticas e a Aliança contra a Fome e a Pobreza, que produzirão suas recomendações ao G20 no primeiro semestre de 2024. </p>
                            <p>Entendemos que ambas as ambições exigem uma revisão mais profunda do atual modelo global de sistemas alimentares, marcado por uma monotonia de consumo que está ligada a uma monotonia de produção alimentar e vice-versa, trazendo graves efeitos para a saúde pública e o equilíbrio das fronteiras planetárias. </p>
                            <p>Na verdade, os sistemas alimentares predominantes são caracterizados pela dependência de combustíveis fósseis (como fertilizantes e pesticidas derivados de combustíveis fósseis), uma produção focada em commodities, alimentos industrializados (principalmente ultraprocessados) e pecuária intensiva, resultando em poluição ambiental, perda da biodiversidade e dos problemas sociais e de saúde.</p>
                            <p>Entendemos que a questão deve ser enfrentada com rigor pelo G20 e que as soluções para enfrentar a monotonia dos sistemas agroalimentares podem e devem vir de experiências bem-sucedidas no Sul Global. </p>
                            <p>Por isso, o Instituto Comida do Amanhã, a Cátedra Josué de Castro de Sistemas Alimentares, o CEBRAP Sustentabilidade, o INCT - Combate à Fome, o Instituto de Defesa do Consumidor (Idec) e o Instituto Fome Zero serão os anfitriões de um debate internacional sobre a monotonia alimentar e a relação com o referido G20 Task Forces 2024, num evento que decorrerá remotamente, no dia 16 de maio – uma semana antes das reuniões ministeriais do G20, em maio.</p>
                            <p>Este é um evento online e restrito a convidados, sujeito à lotação. Por isso, é necessária a inscrição prévia no evento.</p>
                            <p>Aviso de Tratamento de Dados Pessoais: nos termos da Lei Geral de Proteção de Dados Pessoais, Lei 13.709/2018 (“LGPD”) informamos que a Organização do evento "Enfrentando a monotonia do sistema alimentar: Oportunidades de ações nas iniciativas da Presidência Brasileira do G20" tratará os dados pessoais cadastrais relativos a pessoas físicas, representantes e/ou associados da pessoa jurídica para os fins de credenciamento para o evento. A Colab208 produtora do evento garante que o tratamento será feito em observância às melhores práticas de proteção de dados pessoais, conforme as diretrizes e princípios éticos norteadores da LGPD e garantirá o exercício dos direitos salvaguardados por aquela lei.</p>
                        </div>:
                        <div className="text">
                            <p>Two important task-forces created by the Brazilian Presidency at this year's G20 are underway: the Mobilization against Climate Change and the Alliance against Hunger and Poverty, which will produce their recommendations to the G20 in the first half of 2024. </p>
                            <p>We understand that both ambitions require a deeper review of the current global model of food systems, marked by a monotony of consumption that is linked to a monotony of food production and vice versa, bringing severe effects to public health and the balance of planetary boundaries. </p>
                            <p>In fact, predominant food systems are characterized by the dependence on fossil fuels (such as fossil fuel-derived fertilizers and pesticides), a production focused on commodities, industrialized (mostly ultraprocessed) food, and intensive livestock farming, resulting in environmental pollution, loss of biodiversity, and health and social problems. </p>
                            <p>We understand that the issue must be faced rigorously by the G20 and that solutions to address the monotony of agri-food systems can and must come from successful experiences in the Global South. </p>
                            <p>Therefore, Comida do Amanhã Institute, Josué de Castro Chair on Food Systems, CEBRAP Sustentabilidade, INCT - Combate à Fome, Institute of Consumers Defense (Idec) and Instituto Fome Zero will host an international debate on food monotony and the relationship with the aforementioned G20 2024 task forces, in an event that will take place remotely, on the 16th May - the week before the G20 ministerial meetings in May.</p>
                            <p>This is an online and restricted event, by invitation only, and may reach full capacity. Therefore, participants must sign up for the event.</p>
                            <p>Notice of Processing of Personal Data: under the terms of the General Law on Protection of Personal Data, Law 13.709/2018 ("LGPD"), we inform you that the Organization of the event "Against Food System Monotony: Opportunities at G20 Brazilian Presidency initiatives" will process personal registration data relating to individuals, representatives and/or associates of the legal entity for the purposes of accreditation for the event. Colab208, producer of the event, guarantees that the processing will be carried out in compliance with the best personal data protection practices, in accordance with the guidelines and guiding ethical principles of the LGPD and will guarantee the exercise of the rights safeguarded by that law.</p>
                        </div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}


