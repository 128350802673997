import React, { useState, useEffect, useRef, useContext } from 'react';
import { LuCalendarDays } from "react-icons/lu";
import { FaRegSquare, FaSquareCheck, FaRegCircle, FaRegCircleDot } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function Inscricao() {

    const { signed } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [success_message, setSuccessMessage] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [organization, setOrganization] = useState('');
    const [country, setCountry] = useState('');
    const [accept, setAccept] = useState('no');
    
    const nameInput = useRef(null);
    const emailInput = useRef(null);
    const organizationInput = useRef(null);
    const countryInput = useRef(null);

    function handleAccept() {
        if(accept==='yes'){
            setAccept('no');
        }else{
            setAccept('yes');
        }
    }

    function clearMessage(){
        setError(false);
        setErrorMessage('');
        setSuccess(false);
        setSuccessMessage('');
    }

    function clearState(){
        setName('');
        setEmail('');
        setOrganization('');
        setCountry('');
        setAccept('no');
    }

    async function sendRegister(e){
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setSuccessMessage("");

        if(name.trim() === ''){
            nameInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(email.trim() === ''){
            emailInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(organization.trim() === ''){
            organizationInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }else if(country.trim() === ''){
            countryInput.current.focus();
            setLoading(false);
            setError(true);
            setErrorMessage(language.error_input);
            return;
        }

        try{
            const response = await api.post(`/register`, {
                lang,
                name,
                email,
                organization,
                country,
                accept,
                type_subscribe: 'online',
            });

            if(response.data.status==="success"){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setSuccess(true);
                setSuccessMessage(language.success);
                clearState();
            }else{
                setLoading(false);
                setError(true);
                setErrorMessage(language.error_email);
                setSuccess(false);
                setSuccessMessage('');
            }
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
            setSuccess(false);
            setSuccessMessage("");
        }
    }

    return (
        <div className="page">
            <Header />
            <div className="content">
                <div className="full mt-80 mb-40">
                    <form onSubmit={sendRegister} className={`form ${ loading ? 'loading' : '' }`}>
                        <h3 className="title_b mb-60 text-center">
                            {language.menu_inscricao}
                        </h3>
                        <div className="d-input mt-20">
                            <label>{language.name}: <span>*</span></label>
                            <input type="text" ref={nameInput} value={name} onChange={e => {setName(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.email}: <span>*</span></label>
                            <input type="email" ref={emailInput} value={email} onChange={e => {setEmail(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.country}: <span>*</span></label>
                            <input type="text" ref={countryInput} value={country} onChange={e => {setCountry(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-input">
                            <label>{language.organization}: <span>*</span></label>
                            <input type="text" ref={organizationInput} value={organization} onChange={e => {setOrganization(e.target.value);clearMessage();}} />
                        </div>
                        <div className="d-radio">
                            <div className={`d-radio-label ${accept==='yes' ? 'checked' : ''}`} onClick={() => {handleAccept();clearMessage();}}>
                                <div className="d-radio-icon">
                                    {accept==='yes' ?
                                    <FaSquareCheck />:
                                    <FaRegSquare />}
                                </div>
                                <span>{language.accept}</span>
                            </div>
                        </div>
                        {success && <div className="success space mt-20 mb-20">{success_message}</div>}
                        {error && <div className="error space mt-20 mb-20">{error_message}</div>}
                        <div className="d-btn mt-30">
                            <button type="submit" className="btn-send">{language.send} {language.inscricao}</button>
                        </div>
                        <div className="d-input mt-30">
                            <em>{language.alert_register}</em>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
}
